<template>
     <div class="my_quotation">
          <div class="title">{{ L['我的报价'] }}</div>
          <div class="my_quotation_z">
               <div class="box1 flex-row">
                    <div class="flex_row_start_center">
                         <span class="info1">{{ L['询盘标题：'] }}</span>
                         <div class="wrap3 flex-col">
                              <input type="text" v-model="title">
                         </div>
                    </div>

                    <div class="flex_row_start_center">
                         <span class="word3">{{ L['报价时间：'] }}</span>
                         <el-date-picker v-model="dateRange" type="daterange" range-separator="~"
                              :start-placeholder="L['开始时间']" :end-placeholder="L['结束时间']" size="small">
                         </el-date-picker>
                    </div>


                    <div class="flex_row_start_center">
                         <span class="txt1">{{ L['状态:'] }}</span>
                         <div class="wrap6 flex-col">
                              <el-select v-model="state" class="m-2" :placeholder="L['请选择']" size="small">
                                   <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                   </el-option>
                              </el-select>
                         </div>
                    </div>

                    <div class="wrap7 flex-col" @click="confirm">
                         <span class="info3">{{ L['搜索'] }}</span>
                    </div>
                    <div class="wrap8 flex-col" @click="reset">
                         <span class="info4">{{ L['重置'] }}</span>
                    </div>
               </div>

               <div class="box11" v-if="true">
                    <div class="box33">
                         <el-table :data="list" style="width: 100%">
                              <el-table-column width="80">
                                   <template #default="scope">
                                        <div class="flex_row_center_center" v-if="scope.row.productImage">
                                             <img class="pic3" :src="scope.row.productImage" />
                                        </div>
                                        <div class="flex_row_center_center" v-else>
                                             <img class="pic3" src="@/assets/inquiry/inq_icon4.png" />
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column :label="L['报价产品']" width="190" align="center">
                                   <template #default="scope">
                                        <div class="TextGroup2 flex-col justify-between">
                                             <span class="infoBox5">{{ scope.row.productName }}</span>
                                             <div class="main2">
                                                  <span class="word14">{{ L['等共'] }}</span>
                                                  <span class="word15">{{ scope.row.productNum }}</span>
                                                  <span class="txt11">{{ L['件'] }}</span>
                                             </div>
                                        </div>
                                   </template>
                              </el-table-column>

                              <el-table-column prop="totalAmountTax" :label="L['报价总金额(元)']" width="160" align="center" />
                              <el-table-column prop="createTime" :label="L['报价时间']" width="160" align="center" />
                              <el-table-column :label="L['对应询盘']" width="160" align="center">
                                   <template #default="scope">
                                        <span class="infoBox6" @click="seeSupplierInquiry(scope.row.inquireId)">{{
                                             scope.row.title }}>></span>
                                   </template>
                              </el-table-column>

                              <el-table-column prop="stateValue" :label="L['状态']" width="100" align="center" />
                              <el-table-column :label="L['操作']" width="120" align="center">
                                   <template #default="scope">
                                        <div class="outer4 flex-col justify-between">
                                             <span class="info16" @click="seeDetail(scope.row.quoteId)">{{ L['查看详情']
                                             }}</span>
                                             <span class="info16" @click="toEditQuo(scope.row.quoteId)"
                                                  v-if="scope.row.state == 10">{{ L['修改报价'] }}</span>
                                             <span class="info16" @click="init_contract(scope.row.quoteId)"
                                                  v-if="scope.row.state == 20">{{ L['发起合约'] }}</span>
                                             <span class="info16" @click="seeCon(scope.row.quoteId)"
                                                  v-if="scope.row.state == 30 || scope.row.state == 31">{{ L['查看合约'] }}</span>
                                        </div>
                                   </template>
                              </el-table-column>
                         </el-table>
                    </div>

                    <!-- 分页 -->
                    <div class="flex_row_center_center sld_pagination">
                         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                              :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                              :hide-on-single-page="true">
                         </el-pagination>
                    </div>
               </div>

               <div class="inquiry_empty flex_column_center_center" v-else>
                    <img src="@/assets/inquiry/inq_empty.png" alt="">
                    <p>{{ L['暂无报价'] }}</p>
               </div>
          </div>
          <quoDialog ref="quoDialog"></quoDialog>
     </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted, toRefs } from 'vue'
import { inqUtils } from '@/utils/inquiry_utils'
import { useRoute, useRouter } from 'vue-router'
import quoDialog from '@/views/inquiry/components/quoteDialog.vue'
export default {
     components: {
          quoDialog
     },
     setup(props) {

          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const { formatTime } = inqUtils()
          const route = useRoute()
          const router = useRouter()

          const options = ref([
               {
                    label: L['全部'],
                    value: 0
               },
               {
                    label: L['待洽谈'],
                    value: 10
               },
               {
                    label: L['待发起合约'],
                    value: 20
               },
               {
                    label: L['合约中'],
                    value: 30
               },
               {
                    label: L['已完成'],
                    value: 31
               },
               {
                    label: L['已失效'],
                    value: 40
               }
          ])

          const quoList = reactive({
               list: [],
               page: {}
          })

          const page = reactive({
               current: 1,
               pageSize: 10,
               total: 20
          })

          const searchDTO = reactive({
               dateRange: [],
               state: 0,
               title: '',
               createTimeStart: '',
               createTimeEnd: ''
          })


          const confirm = () => {
               let { dateRange, title, createTimeStart, createTimeEnd, state } = searchDTO
               if (dateRange.length) {
                    createTimeStart = formatTime(dateRange[0])
                    createTimeEnd = formatTime(dateRange[1])
                    param.createTimeStart = createTimeStart
                    param.createTimeEnd = createTimeEnd
               }

               if (title) {
                    param.title = title
               } else {
                    delete param.title
               }

               param.state = state
               getQuoList()
          }

          const reset = () => {
               searchDTO.dateRange = []
               searchDTO.title = ''
               delete param.createTimeStart
               delete param.createTimeEnd
               delete param.title
               searchDTO.state = 0
               param.state = searchDTO.state
               param.current = 1
               getQuoList()
          }

          const param = reactive({
               current: 1,
               state: 0
          })

          const seeSupplierInquiry = (inquireId) => {
               router.push({
                    path: '/inquiry/supplier/inq_info',
                    query: {
                         inquireId
                    }
               })
          }


          const getQuoList = () => {

               if (param.state == 0) {
                    delete param.state
               }

               proxy.$get('v3/business/front/supplierInquire/quoteList', param).then(res => {
                    if (res.state == 200) {
                         quoList.list = res.data.list
                         quoList.page = res.data.pagination
                    }
               })
          }

          const init_contract = (quoteId) => {
               router.push({
                    path: '/inquiry/supplier/init_contract',
                    query: {
                         quoteId
                    }
               })
          }

          const seeDetail = (quoteId) => {
               router.push({
                    path: '/inquiry/supplier/quo_info',
                    query: {
                         quoteId
                    }
               })
          }

          const toEditQuo = (quoteId) => {
               proxy.$refs.quoDialog.getQuoInfo(quoteId)
               proxy.$refs.quoDialog.dialogVisible = true

          }

          const seeCon = (quoteId) => {
               router.push({
                    path: '/inquiry/contract_quo',
                    query: {
                         quoteId
                    }
               })
          }



          onMounted(() => {
               getQuoList()
          })


          return {
               page,
               ...toRefs(quoList),
               init_contract,
               seeDetail,
               seeSupplierInquiry,
               ...toRefs(searchDTO),
               confirm,
               reset,
               options,
               toEditQuo,
               seeCon,
               L
          }
     }
}
</script>

<style lang="scss">
.my_quotation {
     .el-date-editor--daterange.el-input__inner {
          width: 250px;
     }

     .el-date-editor .el-range-separator {
          width: 7%;
     }

     .wrap6 .el-input__inner {
          border: none;
     }

     .has-gutter tr th {
          background: #F7F8FA;
     }
}
</style>

<style lang="scss" scoped>
input {
     height: 36px;
     outline: none;
     border: none;

}




.my_quotation {
     float: left;
     width: 1015px;
     background-color: #fff;
     margin-left: 15px;

     .title {
          height: 60px;
          border-bottom: 1px solid $colorI;
          line-height: 60px;
          padding-left: 20px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #666666;
     }


     .my_quotation_z {
          padding: 20px;

          .box1 {

               .info1 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
               }

               .wrap3 {
                    background-color: rgba(255, 255, 255, 1);
                    width: 170px;
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 10px;
                    padding: 0 10px;
               }

               .word3 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    margin: 0px 0 0 22px;
               }

               .wrap4 {
                    background-color: rgba(255, 255, 255, 1);
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 15px;
                    width: 115px;

                    .bd3 {
                         width: 95px;
                         height: 18px;
                         margin: 8px 0 0 10px;

                         .word4 {
                              width: 49px;
                              height: 12px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              margin-top: 4px;
                              display: block;
                         }

                         .icon1 {
                              width: 18px;
                              height: 18px;
                         }
                    }
               }

               .word5 {
                    width: 8px;

                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 17px 0 0 19px;
               }

               .wrap5 {
                    background-color: rgba(255, 255, 255, 1);
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 17px;
                    width: 115px;

                    .section1 {
                         width: 96px;
                         height: 18px;
                         margin: 9px 0 0 11px;

                         .info2 {
                              width: 49px;
                              height: 12px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              margin-top: 2px;
                              display: block;
                         }

                         .label3 {
                              width: 18px;
                              height: 18px;
                         }
                    }
               }

               .txt1 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    display: block;
                    margin: 0px 0 0 28px;
               }

               .wrap6 {
                    background-color: rgba(255, 255, 255, 1);
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    width: 100px;
                    margin: 0px 0 0 11px;

                    .layer3 {
                         width: 85px;
                         height: 11px;
                         margin: 12px 0 0 8px;

                         .txt2 {
                              width: 37px;
                              height: 11px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              display: block;
                         }

                         .label4 {
                              width: 13px;
                              height: 7px;
                              margin-top: 3px;
                         }
                    }
               }

               .wrap7 {
                    background-color: rgba(40, 95, 222, 1);
                    border-radius: 4px;
                    height: 32px;
                    width: 68px;
                    margin: 3px 0 0 31px;
                    cursor: pointer;

                    .info3 {
                         width: 28px;
                         height: 13px;

                         color: rgba(255, 255, 255, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }

               .wrap8 {
                    border-radius: 4px;
                    height: 32px;
                    border: 1px solid rgba(40, 95, 222, 1);
                    width: 68px;
                    margin: 3px 0 0 20px;
                    cursor: pointer;

                    .info4 {
                         width: 29px;
                         height: 13px;

                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }
          }

          .box11 {

               .box2 {
                    background-color: rgba(247, 248, 250, 1);
                    height: 45px;
                    width: 980px;
                    margin: 33px 0 0 1px;
                    border: 1px solid #DCDCDC;
                    border-bottom: none;

                    .bd4 {
                         width: 811px;
                         height: 16px;
                         margin: 16px 0 0 121px;

                         .info5 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              margin-top: 0px;
                         }

                         .txt3 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              margin-left: 133px;
                         }

                         .info6 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              display: block;
                              margin: 0px 0 0 64px;
                         }

                         .info7 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              margin: 0px 0 0 111px;
                         }

                         .txt4 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-left: 101px;
                         }

                         .txt5 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              white-space: nowrap;
                              line-height: 14px;
                              margin: 0px 0 0 78px;
                         }
                    }
               }
          }

          .box33 {

               margin-top: 34px;
               border: 1px solid #DCDCDC;

               .pic3 {
                    width: 50px;
                    height: 49px;
                    margin-top: 1px;
               }

               .TextGroup2 {
                    margin-left: 11px;
                    width: 200px;

                    .infoBox5 {
                         width: 200px;

                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         display: block;
                    }

                    .main2 {
                         font-size: 0;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         margin-top: 7px;

                         .word14 {

                              color: rgba(102, 102, 102, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                         }

                         .word15 {

                              color: rgba(40, 95, 222, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                         }

                         .txt11 {

                              color: rgba(102, 102, 102, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                         }
                    }
               }

               .infoBox6 {
                    color: rgba(40, 95, 222, 1);
                    font-size: 12px;
                    cursor: pointer;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
               }

               .outer4 {

                    .info16 {
                         cursor: pointer;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;

                         &:hover {
                              color: $colorMain;
                         }
                    }


               }

          }
     }

}

.inquiry_empty {

     padding-top: 140px;
     padding-bottom: 200px;

     img {
          width: 120px;
          height: 119px;
     }

     p {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-top: 34px;
     }
}
</style>